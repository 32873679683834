import React, { useContext } from 'react';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import {
  renderPageComponents,
  getStatusAlertComponent,
} from '../util/contentful-render-utils';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import colors from '../themes/main-colors';
import LeftNavMenu from '../components/left-menu-navigation';
import BreadCrumb from '../components/pge-breadcrumb';
import Container from '@material-ui/core/Container';
import './css/contentful-pages.css';
import { LanguageContext } from '../providers/LanguageProvider';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menu: {
      backgroundColor: colors.white,
      padding: theme.spacing(2.5, 7.5, 0, 0),
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    heading: {
      fontSize: theme.spacing(4.5),
      color: colors.noirBlur,
      lineHeight: theme.typography.pxToRem(40),
      fontFamily: 'Forma-DJR-Display',
      padding: theme.spacing(0, 0, 2.5, 0),
      fontWeight: 'bold',
      letterSpacing: '0.720px',
      margin: 0,
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.spacing(3),
        lineHeight: theme.typography.pxToRem(28),
        padding: theme.spacing(0, 0, 1.25, 0),
      },
    },
    description: {
      fontSize: theme.spacing(3),
      color: colors.noirBlur,
      lineHeight: theme.typography.pxToRem(28),
      marginBottom: theme.spacing(2.5),
      fontWeight: 100,
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.spacing(2.5),
        lineHeight: theme.typography.pxToRem(24),
        marginBottom: theme.spacing(1.25),
      },
    },
    image: {
      width: '100%',
      borderRadius: 5,
      height: 275,
      objectFit: 'cover',
    },
  }),
);

export const ListTemplate = ({ pageContext, data }: any) => {
  const classes = useStyles();
  const { language } = useContext(LanguageContext);
  const page = pageContext[language || 'en'];

  // ** Start temporary rollback of localization for public pages **
  const listPageData = data.allContentfulPageList?.nodes[0];
  // const listPageData =
  //   data.allContentfulPageList?.nodes.find(
  //     (node: any) => node.node_locale === language,
  //   ) || data.allContentfulPageList?.nodes[0];
  // ** End temporary rollback of localization for public pages **

  const listImageURL = listPageData?.image?.file?.url;

  if (page?.sectionNavArray && page?.sectionNavArray?.length > 0) {
    page.sectionNavArray[0]['menuOpen'] = true;
  }

  return (
    <>
      <Helmet bodyAttributes={{ class: 'TemplatePage' }}>
        <title>{listPageData?.browserTitle || listPageData?.pageTitle}</title>
        {listPageData?.metaDescriptionSeo && (
          <meta name="description" content={listPageData.metaDescriptionSeo} />
        )}
        {listPageData?.excludeFromGoogleSearch && (
          <meta name="robots" content="noindex" />
        )}
      </Helmet>

      <Container className="component-container">
        {page?.sectionNavArray && page?.sectionNavArray.length > 0 && (
          <div className={classes.menu}>
            <LeftNavMenu
              leftNavMenuItems={page.sectionNavArray}
              selectedItem={page.slug}
            />
          </div>
        )}
        <div style={{ width: '100%' }}>
          <BreadCrumb path={page?.breadcrumb} />
          {getStatusAlertComponent(listPageData?.statusAlert)}
          {listPageData?.pageTitle && (
            <h1 className={classes.heading}>{listPageData?.pageTitle}</h1>
          )}
          {listPageData?.subtitle && (
            <div className={classes.description}>{listPageData?.subtitle}</div>
          )}
          {listImageURL && <img className={classes.image} src={listImageURL} />}
          {renderPageComponents({
            entriesData: listPageData?.contentEntries || null,
            pageTemplate: listPageData?.pageTemplate || null,
          })}
        </div>
      </Container>
    </>
  );
};
export default ListTemplate;

export const query = graphql`
  query PageListing($slug: String!) {
    allContentfulPageList(filter: { slug: { eq: $slug } }) {
      nodes {
        node_locale
        contentful_id
        entryName
        shortMenuTitle
        slug
        pageTitle
        pageShortDescription
        subtitle
        pageTemplate
        browserTitle
        metaDescriptionSeo
        excludeFromGoogleSearch
        excludeFromXmlSiteMap
        statusAlert {
          ...ModuleStatusAlert
        }
        image {
          file {
            contentType
            fileName
            url
          }
        }
        contentEntries {
          ...ModuleContentBody
          ...ModuleNote
          ...ModuleStatusAlert
          ...ModuleCallOut
          ...ModuleContact
          ...ModuleContentList
          ...ModuleDocumentList
          ...ModuleRelated
          ...ProgramCard
          ...StaticList
        }
      }
    }
  }
`;
